import request from '@/utils/request2'

// 获取需要本人评价的360考核表列表接口
export function getUser360CheckList (params) {
  const { empid } = params
  return request({
    url: '/api/wxjx/getUser360CheckList',
    method: 'get',
    params: { empid }
  })
}

// 获取单笔或多笔360评价表详细信息接口
export function getEmp360PaperDetail (data) {
  return request({
    url: '/api/wxjx/getEmp360PaperDetail',
    method: 'post',
    data: data
  })
}

// 获取本人的360评价人信息列表
export function getUser360PjrList (params) {
  const { empid } = params
  return request({
    url: '/api/wxjx/getUser360PjrList',
    method: 'get',
    params: { empid }
  })
}

// 同意360评价人信息
export function agreeUser360Pjr (params) {
  const { autoid, username } = params
  return request({
    url: '/api/wxjx/agreeUser360Pjr',
    method: 'get',
    params: { autoid, username }
  })
}

// 不同意360评价人信息
export function notAgreeUser360Pjr (params) {
  const { autoid, username, notagreereason } = params
  return request({
    url: '/api/wxjx/notAgreeUser360Pjr',
    method: 'get',
    params: { autoid, username, notagreereason }
  })
}

// 保存单笔或多笔360评价表详细信息接口
export function saveEmp360PaperDetail (data) {
  return request({
    url: '/api/wxjx/saveEmp360PaperDetail',
    method: 'post',
    data: data
  })
}

// 获取“我的360指标”记录列表（倒序排列）
export function getMy360KhList (params) {
  const { empid } = params
  return request({
    url: '/api/wxjx/getMy360KhList',
    method: 'get',
    params: { empid }
  })
}

// 7.3.查看某个“360指标方案”所有指标明细接口
export function getMy360IndicatorList (data) {
  return request({
    url: '/api/wxjx/getMy360IndicatorList',
    method: 'get',
    params: data
  })
}

// 7.4.查看某个“360指标方案”的单个指标详细信息接口
export function getMy360IndicatorDetail (data) {
  return request({
    url: '/api/wxjx/getMy360IndicatorDetail',
    method: 'get',
    params: data
  })
}

// 7.5.修改某个“360指标方案”的单个指标详细信息接口
export function saveMy360Indicator (data) {
  return request({
    url: '/api/wxjx/saveMy360Indicator',
    method: 'post',
    data: data
  })
}

// 7.6.新增某个“360指标方案”的单个指标详细信息接
export function addMy360Indicator (data) {
  return request({
    url: '/api/wxjx/addMy360Indicator',
    method: 'post',
    data: data
  })
}

// 7.7.删除某个“360指标方案”的单个指标详细信息接口
export function deleteMy360Indicator (data) {
  return request({
    url: '/api/wxjx/deleteMy360Indicator',
    method: 'get',
    params: data
  })
}

// 7.8.提交“360指标方案”接口
export function postMy360PlanIndicator (data) {
  return request({
    url: '/api/wxjx/postMy360PlanIndicator',
    method: 'get',
    params: data
  })
}

// 7.9.获取“我的KPI指标”记录列表（倒序排列）
export function getMyKpiKhList (params) {
  const { empid } = params
  return request({
    url: '/api/wxjx/getMyKpiKhList',
    method: 'get',
    params: { empid }
  })
}

// 7.10.查看某个“KPI指标方案”所有指标明细接口
export function getMyKpiIndicatorList (data) {
  return request({
    url: '/api/wxjx/getMyKpiIndicatorList',
    method: 'get',
    params: data
  })
}

// 7.11.查看某个“KPI指标方案”的单个指标详细信息接口
export function getMyKpiIndicatorDetail (data) {
  return request({
    url: '/api/wxjx/getMyKpiIndicatorDetail',
    method: 'get',
    params: data
  })
}

// 7.12.修改某个“KPI指标方案”的单个指标详细信息接口
export function saveMyKpiIndicator (data) {
  return request({
    url: '/api/wxjx/saveMyKpiIndicator',
    method: 'post',
    data: data
  })
}

// 7.13.新增某个“KPI指标方案”的单个指标详细信息接口
export function addMyKpiIndicator (data) {
  return request({
    url: '/api/wxjx/addMyKpiIndicator',
    method: 'post',
    data: data
  })
}

// 7.14.删除某个“KPI指标方案”的单个指标详细信息接口
export function deleteMyKpiIndicator (data) {
  return request({
    url: '/api/wxjx/deleteMyKpiIndicator',
    method: 'get',
    params: data
  })
}

// 7.15.提交“KPI指标方案”接口
export function postMyKpiPlanIndicator (data) {
  return request({
    url: '/api/wxjx/postMyKpiPlanIndicator',
    method: 'get',
    params: data
  })
}

// 9.1.获取待审批的360考核方案列表
export function getMy360ForCheckList (data) {
  return request({
    url: '/api/wxjx/getMy360ForCheckList',
    method: 'get',
    params: data
  })
}

// 9.3.同意或不同意360考核方案接口
export function agreeMy360KhIndicator (data) {
  return request({
    url: '/api/wxjx/agreeMy360KhIndicator',
    method: 'get',
    params: data
  })
}

// 9.6.同意或不同意KPI考核方案接口
export function agreeMyKpiKhIndicator (data) {
  return request({
    url: '/api/wxjx/agreeMyKpiKhIndicator',
    method: 'get',
    params: data
  })
}

// 9.4.获取待审批的KPI考核方案列表
export function getMyKpiForCheckList (data) {
  return request({
    url: '/api/wxjx/getMyKpiForCheckList',
    method: 'get',
    params: data
  })
}

// 10.1.获取待评价的KPI方案记录
export function getKpiPlanCheckList (data) {
  return request({
    url: '/api/wxjx/getKpiPlanCheckList',
    method: 'get',
    params: data
  })
}

// 8.1.获取我的历史360考核方案列表
export function getMy360HisRecList (data) {
  return request({
    url: '/api/wxjx/getMy360HisRecList',
    method: 'get',
    params: data
  })
}

// 10.2.获取某个待评价的KPI方案明细数据接口
export function getKpiPlanIndicatorDetail (data) {
  return request({
    url: '/api/wxjx/getKpiPlanIndicatorDetail',
    method: 'get',
    params: data
  })
}

// 8.2.获取我的历史KPI考核方案列表
export function getMyKpiHisRecList (data) {
  return request({
    url: '/api/wxjx/getMyKpiHisRecList',
    method: 'get',
    params: data
  })
}

// 8.3.查看我的某个360考核方案明细接口
export function getMy360HisRecDetail (data) {
  return request({
    url: '/api/wxjx/getMy360HisRecDetail',
    method: 'get',
    params: data
  })
}

// 8.4.查看我的某个KPI考核方案明细接口
export function getMyKpiHisRecDetail (data) {
  return request({
    url: '/api/wxjx/getMyKpiHisRecDetail',
    method: 'get',
    params: data
  })
}
// 保存待评价的KPI方案明细数据接口（批量保存）
export function saveKpiPlanIndicatorDetail (data) {
  return request({
    url: '/api/wxjx/saveKpiPlanIndicatorDetail',
    method: 'post',
    data: data
  })
}

// 获取首页绩效平台相关数据
export function getJxCount (params) {
  const { empid } = params
  return request({
    url: '/api/wxjx/getJxCount',
    method: 'get',
    params: { empid }
  })
}

// 360指标的指标类别下拉选项
export function getMy360IndicatorTypeList (data) {
  return request({
    url: '/api/wxjx/getMy360IndicatorTypeList',
    method: 'get',
    params: data
  })
}

// KPI指标的指标类别下拉选项
export function getMyKpiIndicatorTypeList (data) {
  return request({
    url: '/api/wxjx/getMyKpiIndicatorTypeList',
    method: 'get',
    params: data
  })
}

// 获取通用树结构
export function getCommTreeData (data) {
  return request({
    url: '/api/wx/getCommTreeData',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-datacharset=UTF-8' }
  })
}

// 13.1获取申诉界面详细信息接口
export function getSsDetail (data) {
  return request({
    url: '/api/wxjx/getSsDetail',
    method: 'get',
    params: data
  })
}

// 13.2获取期望等级下拉列表接口
export function getJxLevel (data) {
  return request({
    url: '/api/wxjx/getJxLevel',
    method: 'get',
    params: data
  })
}

// 13.3提交绩效申诉内容接口
export function postJxSs (data) {
  return request({
    url: '/api/wxjx/postJxSs',
    method: 'post',
    data: data,
    headers: { 'Content-Type': 'multipart/form-datacharset=UTF-8' }
  })
}

//13.4获取“我的申诉”列表接口
export function getMySsList (data) {
  return request({
    url: '/api/wxjx/getMySsList',
    method: 'get',
    params: data
  })
}

// 13.5获取“下级申诉”列表接口
export function getXjSsList (data) {
  return request({
    url: '/api/wxjx/getXjSsList',
    method: 'get',
    params: data
  })
}

// 13.6同意或否决下级申诉的接口
export function postXjSsInfo (data) {
  return request({
    url: '/api/wxjx/postXjSsInfo',
    method: 'post',
    data: data,
    headers: { 'Content-Type': 'multipart/form-datacharset=UTF-8' }
  })
}

// 14.1获取“我的面谈”列表接口
export function getMyMtList (data) {
  return request({
    url: '/api/wxjx/getMyMtList',
    method: 'get',
    params: data
  })
}

// 14.2获取面谈详细信息接口
export function getMtDetail (data) {
  return request({
    url: '/api/wxjx/getMtDetail',
    method: 'get',
    params: data
  })
}

// 14.3提交本人面谈详细信息接口
export function postMyMtDetail (data) {
  return request({
    url: '/api/wxjx/postMyMtDetail',
    method: 'post',
    data: data,
    headers: { 'Content-Type': 'multipart/form-datacharset=UTF-8' }
  })
}

// 14.4获取“下级面谈评价”列表接口
export function getXjMtList (data) {
  return request({
    url: '/api/wxjx/getXjMtList',
    method: 'get',
    params: data
  })
}

// 14.5提交下级面谈详细信息接口
export function postXjMtDetail (data) {
  return request({
    url: '/api/wxjx/postXjMtDetail',
    method: 'post',
    data: data,
    headers: { 'Content-Type': 'multipart/form-datacharset=UTF-8' }
  })
}

// 15.1获取“公共统计数据”所有考核周期列表接口
export function getPubCiTimeList (data) {
  return request({
    url: '/api/wxjx/getPubCiTimeList',
    method: 'get',
    params: data
  })
}

// 15.6获取“个人统计数据”所有考核周期列表接口
export function getPerCiTimeList (data) {
  return request({
    url: '/api/wxjx/getPerCiTimeList',
    method: 'get',
    params: data
  })
}

// 15.3获取某个考核周期的“公共统计数据”列表接口
export function getPubCiList (data) {
  return request({
    url: '/api/wxjx/getPubCiList',
    method: 'get',
    params: data
  })
}

// 15.5删除单笔“公共统计数据”记录明细接口
export function deletePubCiRecord (data) {
  return request({
    url: '/api/wxjx/deletePubCiRecord',
    method: 'get',
    params: data
  })
}

// 15.3获取某个“公共统计数据”记录明细接口
export function getPubCiDetail (data) {
  return request({
    url: '/api/wxjx/getPubCiDetail',
    method: 'get',
    params: data
  })
}

// 15.4新增保存单笔“公共统计数据”记录明细接口
export function addPubCiDetail (data) {
  return request({
    url: '/api/wxjx/addPubCiDetail',
    method: 'post',
    data: data
  })
}

// 15.7获取某个考核周期的“个人统计数据”列表接口
export function getPerCiList (data) {
  return request({
    url: '/api/wxjx/getPerCiList',
    method: 'get',
    params: data
  })
}

// 15.11删除单笔“个人统计数据”记录明细接口
export function deletePerCiRecord (data) {
  return request({
    url: '/api/wxjx/deletePerCiRecord',
    method: 'get',
    params: data
  })
}

// 15.9新增保存单笔“个人统计数据”记录明细接口
export function addPerCiDetail (data) {
  return request({
    url: '/api/wxjx/addPerCiDetail',
    method: 'post',
    data: data
  })
}

// 15.8获取某个“个人统计数据”记录明细接口
export function getPerCiDetail (data) {
  return request({
    url: '/api/wxjx/getPerCiDetail',
    method: 'get',
    params: data
  })
}

// 15.10修改保存单笔“个人统计数据”记录明细接口
export function savePerCiDetail (data) {
  return request({
    url: '/api/wxjx/savePerCiDetail',
    method: 'post',
    data: data
  })
}

// 修改保存单笔“公共统计数据”记录明细接口
export function savePubCiDetail (data) {
  return request({
    url: '/api/wxjx/savePubCiDetail',
    method: 'post',
    data: data
  })
}

// 获取我的KPI指标新增默认值接口
export function getMyKpiNewDefaultValue (data) {
  return request({
    url: '/api/wxjx/getMyKpiNewDefaultValue',
    method: 'get',
    params: data
  })
}

// 获取某个kpi总分
export function getMyKpiTotalScore (data) {
  return request({
    url: '/api/wxjx/getMyKpiTotalScore',
    method: 'get',
    params: data
  })
}

// 确认绩效申诉
export function qrJxSs (data) {
  return request({
    url: '/api/wxjx/qrJxSs',
    method: 'get',
    params: data
  })
}

// 上传文件的base64
export function upLoadBase64FileFixName (data) {
  return request({
    url: '/api/wx/upLoadBase64FileFixName',
    method: 'post',
    data: data,
    headers: { 'Content-Type': 'multipart/form-datacharset=UTF-8' }
  })
}

// 获取“指标权重”下拉列表
export function getIndicatorPerList (data) {
  return request({
    url: '/api/wxjx/getIndicatorPerList',
    method: 'get',
    params: data
  })
}

// 获取“评分上限”下拉列表接口
export function getTgValueList (data) {
  return request({
    url: '/api/wxjx/getTgValueList',
    method: 'get',
    params: data
  })
}

//8.5确认本人的KPI考核结果
export function empKhResCheck (data) {
  return request({
    url: '/api/wxjx/empKhResCheck',
    method: 'get',
    params: data
  })
}